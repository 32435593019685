/**
 * @file getBeaconUrl.ts
 * @author markJia(markjia@tencent.com)
 */

import { request } from '../../services/zhb/request';

// 请求列表
export interface RespApiGetBeaconConfigItem {
  id: number;
  // 企业corpId
  title: string;
  // 灯塔页面名称
  pageId: number;
}

// 生成分享链接
export interface ReqCreateBeaconShareUrlBody {
  pageId: number;
  expireTime: number;
  params: string;
  iframeWebsite: string;
}

export interface ResCreateBeaconShareUrl {
  code: number;
  message: string;
  data: {
    url: string;
  }
}

export const getShareBeaconUrl = (params: ReqCreateBeaconShareUrlBody): Promise<ResCreateBeaconShareUrl> => request<string>({
  url: '/api/marketing/wecom/lighthouse/datatalk/page/info',
  method: 'post',
  data: params,
}).then(res => JSON.parse(res));
