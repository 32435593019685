import React from 'react';
import * as H from 'react-router';
import { ACTIONTYPE } from '../routes/WechatAI/chat/stores/provider';
import { AuthoritylistResponse } from '../types/models/privateTraffic/wecomAuthorityList';
import { Menu } from '../utils/menu';

export interface CorpContextInfo {
  loading: boolean;
  id: string;
  name: string;
  isConversation: boolean;
  thirdCorpList: AuthoritylistResponse['data']['list'],
}

export interface GlobalContextState {
  history: H.RouteChildrenProps['history'];
  corpInfo: CorpContextInfo;
  MENU: Menu;
  authList: number[];
  isAdmin: boolean;
  phone: string;
}

export interface GlobalContextValue {
  state: GlobalContextState;
  dispatch: React.Dispatch<ACTIONTYPE>;
  switchCorp: (id: string) => Promise<void>;
}

const Context = React.createContext<GlobalContextValue | null>(null);
export default Context;
