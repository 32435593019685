/**
  转化宝菜单配置文件
  如果第一级有component，则走自定义逻辑，参考“AI助手”
 */

import { ScrmData } from './scrm';
import { DataReportData } from './dataReport';
import { SettingsData } from './settings';
import { MarketingData } from './marketing';
// 全链路分析下线
// import { AnalysisData } from './analysis';

// import { AILabData } from './aiLab';
// import { ShoppingHelpData } from './shoppingHelp';
import { MenuItem } from './interface';


export const MENU_DATA: MenuItem[] = [
  DataReportData,
  MarketingData,
  // AnalysisData,
  // ShoppingHelpData,
  // AILabData,
  ScrmData,
  SettingsData,
];
