import React, { useMemo } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import dynamic from 'store/dynamic';
import { hot } from 'react-hot-loader/root';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import Layout from './layouts/index';

import GlobalDataProvider from './stores/provider';
// import HomePage from './routes/Home';
import NotFoundPage from './routes/Home/404';

// 全局样式
import 'maker/components/Style';
import { useGlobalStore } from './hooks/useGlobalStore';
// 递归遍历数组获取所有路由
const loopChild: any = (data: any) => {
  let newItem: any = [];
  for (const item of data) {
    if (item.children && item.children.length > 0) {
      newItem = newItem.concat(loopChild(item.children));
    } else if (item.url) {
      newItem.push({
        path: item.url,
        exact: true,
        component: item.component,
      });
    }
  }
  return newItem;
};

const MainContent = (): JSX.Element => {
  const state = useGlobalStore();
  const routes = useMemo(() => loopChild(state.MENU.menuData), [state.MENU.menuData]);

  // 带菜单的页面
  return (
    <Layout>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        {renderRoutes(routes)}
      </QueryParamProvider>
    </Layout>
  );
};

const App = () => (
  <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
      <GlobalDataProvider>
        <Switch>
          {/* 登录相关页面 */}
          {/* 官网主页直接跳转登录页 https://tapd.woa.com/70100120/prong/stories/view/1070100120118086790 */}
          {/* <Route path={'/'} exact component={HomePage} /> */}
          <Route path={'/'} exact component={dynamic({
            component: () => import('./routes/Login'),
          })} />
          <Route path={'/login'} exact component={dynamic({
            component: () => import('./routes/Login'),
          })} />
          <Route path={'/login/ssoLogin'} exact component={dynamic({
            component: () => import('./routes/Login/access'),
          })} />
          <Route path={'/login/mlogin'} exact component={dynamic({
            component: () => import('./routes/Login/super'),
          })} />
          <Route path={'/login/register'} exact component={dynamic({
            component: () => import('./routes/Login/register'),
          })} />
          {/* 不带菜单的页面 */}
          <Route path={'/normal/adOpIntro'} exact component={dynamic({
            component: () => import('./routes/DataReport/adoptimize/introduce'),
          })} />
          <Route path={'/normal/sandIntro'} exact component={dynamic({
            component: () => import('./routes/Marketing/sandtable/introduce'),
          })} />
          <Route path={'/normal/experiment/queryDetailInfo'} exact component={dynamic({
            component: () => import('./routes/Experiment/QueryDetailInfo'),
          })} />
          <Route path={'/normal/agreement/basic'} exact component={dynamic({
            component: () => import('./routes/AgreementDocs/docs/basic'),
          })} />
          <Route path={'/normal/agreement/privacy'} exact component={dynamic({
            component: () => import('./routes/AgreementDocs/docs/privacy'),
          })} />
          <Route path={'/normal/agreement/dataProtection'} exact component={dynamic({
            component: () => import('./routes/AgreementDocs/docs/dataProtection'),
          })} />
          {/* 404页面 */}
          <Route path={'/404'} component={NotFoundPage} />
          <MainContent />
        </Switch>
      </GlobalDataProvider>
    </BrowserRouter>
  </DndProvider>
);

export default hot(App);
